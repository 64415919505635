import { firebase } from '@/firebase'

export interface IApplication {
  verifiedSections: {
    'Applicant Information': boolean
    Education: boolean
    'Professional References': boolean
    'Previous Employment': boolean
  },
  created_date: firebase.firestore.Timestamp
  acknowledgement: boolean
  notesFromApplicant: string
  lastName: string
  firstName: string
  middleIntial: string
  streetAddress: string
  aptUnitNo: string
  city: string
  state: string
  postalCode: string
  phone: string
  email: string
  driversLicense: string
  cdl: string
  dob: string
  desiredSalary: number
  positionApplyingFor: string
  referredBy: string
  citizen: true,
  authorizedToWork: true,
  previouslyWorkedForThisCompany: boolean
  previouslyWorkedNotes: string
  highSchoolName: string
  highSchoolAddress: string
  highSchoolAttendedFrom: string
  highSchoolAttendedTo: string
  highSchoolGraduate: string
  highSchoolDegree: string
  collegeName: string
  collegeAddress: string
  collegeAttendedFrom: string
  collegeAttendedTo: string
  collegeGraduate: string
  collegeDegree: string
  consentForMvrCheck: boolean
  consentForBackgroundCheck: boolean
  educationNotes: string
  referencesFullName1: string
  referencesPhone1: string
  referencesFullName2: string
  referencesPhone2: string
  referencesFullName3: string
  referencesPhone3: string
  previousEmployerCount: number,
  previousEmployer1Company: string
  previousEmployer1Phone: string
  previousEmployer1Address: string
  previousEmployer1Supervisor: string
  previousEmployer1JobTitle: string
  previousEmployer1StartingSalary: number
  previousEmployer1EndingSalary: number
  previousEmployer1Responsibilities: string
  previousEmployer1From: string
  previousEmployer1To: string
  previousEmployer1ReasonForLeaving: string
  previousEmployer1Contact: string
  previousEmployer2Company?: string
  previousEmployer2Phone?: string
  previousEmployer2Address?: string
  previousEmployer2Supervisor?: string
  previousEmployer2JobTitle?: string
  previousEmployer2StartingSalary?: number
  previousEmployer2EndingSalary?: number
  previousEmployer2Responsibilities?: string
  previousEmployer2From?: string
  previousEmployer2To?: string
  previousEmployer2ReasonForLeaving?: string
  previousEmployer2Contact?: string
  previousEmployer3Company?: string
  previousEmployer3Phone?: string
  previousEmployer3Address?: string
  previousEmployer3Supervisor?: string
  previousEmployer3JobTitle?: string
  previousEmployer3StartingSalary?: number
  previousEmployer3EndingSalary?: number
  previousEmployer3Responsibilities?: string
  previousEmployer3From?: string
  previousEmployer3To?: string
  previousEmployer3ReasonForLeaving?: string
  previousEmployer3Contact?: string
  resume?: object
}

export const cleanApplication = (form: any): IApplication => {
  let obj: IApplication = {
    verifiedSections: {
      'Applicant Information': form.verifiedSections['Applicant Information'],
      Education: form.verifiedSections.Education,
      'Professional References': form.verifiedSections['Professional References'],
      'Previous Employment': form.verifiedSections['Previous Employment']
    },
    created_date: firebase.firestore.Timestamp.now(),
    acknowledgement: form.acknowledgement || false,
    notesFromApplicant: form.notesFromApplicant || null,
    lastName: form.lastName || null,
    firstName: form.firstName || null,
    middleIntial: form.middleIntial || null,
    streetAddress: form.streetAddress || null,
    aptUnitNo: form.aptUnitNo || null,
    city: form.city || null,
    state: form.state || null,
    postalCode: form.postalCode || null,
    phone: cleanPhone(form.phone) || null,
    email: form.email || null,
    driversLicense: form.driversLicense || null,
    cdl: form.cdl || null,
    dob: form.dob || null,
    desiredSalary: form.desiredSalary || 0,
    positionApplyingFor: form.positionApplyingFor || null,
    referredBy: form.referredBy || null,
    citizen: form.citizen || null,
    authorizedToWork: form.authorizedToWork || null,
    previouslyWorkedForThisCompany: form.previouslyWorkedForThisCompany || null,
    previouslyWorkedNotes: form.previouslyWorkedNotes || null,
    highSchoolName: form.highSchoolName || null,
    highSchoolAddress: form.highSchoolAddress || null,
    highSchoolAttendedFrom: form.highSchoolAttendedFrom || null,
    highSchoolAttendedTo: form.highSchoolAttendedTo || null,
    highSchoolGraduate: form.highSchoolGraduate || null,
    highSchoolDegree: form.highSchoolDegree || null,
    collegeName: form.collegeName || null,
    collegeAddress: form.collegeAddress || null,
    collegeAttendedFrom: form.collegeAttendedFrom || null,
    collegeAttendedTo: form.collegeAttendedTo || null,
    collegeGraduate: form.collegeGraduate || null,
    collegeDegree: form.collegeDegree || null,
    educationNotes: form.educationNotes || null,
    referencesFullName1: form.referencesFullName1 || null,
    referencesPhone1: cleanPhone(form.referencesPhone1) || null,
    referencesFullName2: form.referencesFullName2 || null,
    referencesPhone2: cleanPhone(form.referencesPhone2) || null,
    referencesFullName3: form.referencesFullName3 || null,
    referencesPhone3: cleanPhone(form.referencesPhone3) || null,
    previousEmployerCount: form.previousEmployerCount || 0,
    previousEmployer1Company: form.previousEmployer1Company || null,
    previousEmployer1Phone: cleanPhone(form.previousEmployer1Phone) || null,
    previousEmployer1Address: form.previousEmployer1Address || null,
    previousEmployer1Supervisor: form.previousEmployer1Supervisor || null,
    previousEmployer1JobTitle: form.previousEmployer1JobTitle || null,
    previousEmployer1StartingSalary: form.previousEmployer1StartingSalary || null,
    previousEmployer1EndingSalary: form.previousEmployer1EndingSalary || null,
    previousEmployer1Responsibilities: form.previousEmployer1Responsibilities || null,
    previousEmployer1From: form.previousEmployer1From || null,
    previousEmployer1To: form.previousEmployer1To || null,
    previousEmployer1ReasonForLeaving: form.previousEmployer1ReasonForLeaving || null,
    previousEmployer1Contact: form.previousEmployer1Contact || null,
    // Added in 2023
    consentForMvrCheck: form.consentForMvrCheck || false,
    consentForBackgroundCheck: form.consentForBackgroundCheck || false
  }
  if (form.previousEmployer2Company) {
    obj = {
      ...obj,
      previousEmployer2Company: form.previousEmployer2Company || null,
      previousEmployer2Phone: cleanPhone(form.previousEmployer2Phone) || null,
      previousEmployer2Address: form.previousEmployer2Address || null,
      previousEmployer2Supervisor: form.previousEmployer2Supervisor || null,
      previousEmployer2JobTitle: form.previousEmployer2JobTitle || null,
      previousEmployer2StartingSalary: form.previousEmployer2StartingSalary || null,
      previousEmployer2EndingSalary: form.previousEmployer2EndingSalary || null,
      previousEmployer2Responsibilities: form.previousEmployer2Responsibilities || null,
      previousEmployer2From: form.previousEmployer2From || null,
      previousEmployer2To: form.previousEmployer2To || null,
      previousEmployer2ReasonForLeaving: form.previousEmployer2ReasonForLeaving || null,
      previousEmployer2Contact: form.previousEmployer2Contact || null
    }
  }

  if (form.previousEmployer3Company) {
    obj = {
      ...obj,
      previousEmployer3Company: form.previousEmployer3Company || null,
      previousEmployer3Phone: cleanPhone(form.previousEmployer3Phone) || null,
      previousEmployer3Address: form.previousEmployer3Address || null,
      previousEmployer3Supervisor: form.previousEmployer3Supervisor || null,
      previousEmployer3JobTitle: form.previousEmployer3JobTitle || null,
      previousEmployer3StartingSalary: form.previousEmployer3StartingSalary || null,
      previousEmployer3EndingSalary: form.previousEmployer3EndingSalary || null,
      previousEmployer3Responsibilities: form.previousEmployer3Responsibilities || null,
      previousEmployer3From: form.previousEmployer3From || null,
      previousEmployer3To: form.previousEmployer3To || null,
      previousEmployer3ReasonForLeaving: form.previousEmployer3ReasonForLeaving || null,
      previousEmployer3Contact: form.previousEmployer3Contact || null
    }
  }
  if (form.resume && form.resume.uuid) {
    obj.resume = form.resume || {}
  }
  return obj
}

const cleanPhone = (phone: any): string => {
  if (phone) {
    return phone.replace(/\D/g, '').toString()
  }
  return phone
}
