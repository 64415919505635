<template>
  <div>
    <v-card flat>
      <v-card-text class="pa-0">
          <v-form ref="form">
            <v-row class="ma-0">
              <v-col
                v-for="i in formFields"
                :key="i.prop"
                :cols="i.cols ? i.cols : 12"
                :sm="i.sm ? i.sm : null"
                :md="i.md ? i.md : null"
                class="px-0 px-md-3"
              >
                <v-checkbox
                  v-if="i.type === 'checkbox'"
                  :input-value="ea[i.prop]"
                  class="mt-1 mx-1"
                  :label="lang === 'English' ? i.label : i.labelEs"
                  @change="updateField(i.prop, $event, i.label)"
                />
                <v-autocomplete
                  v-else-if="i.type === 'autocomplete'"
                  :value="ea[i.prop]"
                  :autocomplete="i.autocomplete"
                  class="mt-0 mx-1"
                  dense
                  :items="lang === 'English' ? i.items : i.itemsEs"
                  item-text="label"
                  item-value="code"
                  :label="lang === 'English' ? i.label : i.labelEs"
                  outlined
                  :rules="i.rules"
                  @change="updateField(i.prop, $event, i.label)"
                />
                <v-textarea
                  v-else-if="i.type === 'textarea'"
                  :value="ea[i.prop]"
                  auto-grow
                  class="mx-1"
                  dense
                  :label="lang === 'English' ? i.label : i.labelEs"
                  :rules="i.rules"
                  outlined
                  rows="3"
                  @input="updateField(i.prop, $event, i.label)"
                />
                <template v-else-if="i.type === 'radio-group-yes-no'">
                  <!-- {{ i.label }} -->
                  <v-radio-group
                    :value="ea[i.prop]"
                    class="mx-1"
                    @change="updateField(i.prop, $event, i.label)"
                  >
                    <template v-slot:label>
                        {{ lang === 'English' ? i.label : i.labelEs }}
                    </template>
                    <div class="d-flex align-center mt-1">
                      <div>
                        <v-radio :label="lang === 'English' ? 'Yes' : 'Sí'" :value="true" color="secondary" />
                      </div>
                      <div class="ml-2">
                        <v-radio label="No" :value="false" color="secondary" />
                      </div>
                    </div>
                  </v-radio-group>
                </template>
                <template v-else-if="i.type === 'spacer'">

                </template>
                <v-menu
                  v-else-if="i.type === 'dob'"
                  v-model="showDobPicker"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  nudge-bottom="40"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="ea.dob"
                      color="secondary"
                      dense
                      :label="lang === 'English' ? 'Date of Birth' : 'Fecha de Nacimiento'"
                      outlined
                      prepend-inner-icon="fa-solid fa-calendar-day"
                      readonly
                      :rules="i.rules"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :value="ea.dob"
                    color="secondary"
                     :max="new Date().toISOString().substr(0, 10)"
                    show-current
                    @input="showDobPicker = false, updateField(i.prop, $event, i.label)"
                  />
                </v-menu>
                <v-text-field
                  v-else
                  :value="ea[i.prop]"
                  :autocomplete="i.autocomplete"
                  class="mx-1"
                  dense
                  :label="lang === 'English' ? i.label : i.labelEs"
                  outlined
                  :rules="i.rules"
                  :type="i.inputType ? i.inputType : 'text'"
                  @input="updateField(i.prop, $event, i.label)"
                />
              </v-col>
            </v-row>
          </v-form>
      </v-card-text>
      <AppSectionActions
        hideBack
        @continue-step="continueStep"
      />
    </v-card>
  </div>
</template>

<script>
import { requiredRules, salaryRules, requiredPhoneRules } from '@/core/utils'
import { states } from '@/core/constants'
import { updateField } from '../utils'
import AppSectionActions from './AppSectionActions'
export default {
  components: {
    AppSectionActions
  },
  data () {
    return {
      states,
      dob: null,
      showDobPicker: false,
      formFields: [
        {
          prop: 'lastName',
          label: 'Last Name',
          labelEs: 'Apellido',
          rules: requiredRules('Last Name/Apellido'),
          autocomplete: 'family-name',
          md: 5
        },
        {
          prop: 'firstName',
          label: 'First Name',
          labelEs: 'Nombre',
          rules: requiredRules('First Name/Nombre'),
          autocomplete: 'given-name',
          md: 5
        },
        {
          prop: 'middleIntial',
          label: 'Middle Initial',
          labelEs: 'SN',
          // rules: requiredRules('Middle Initial/SN'),
          md: 2
        },
        {
          prop: 'streetAddress',
          label: 'Street Address',
          labelEs: 'Dirección',
          rules: requiredRules('Street Address'),
          sm: 9
        },
        {
          prop: 'aptUnitNo',
          label: 'Apt/Unit #',
          labelEs: 'Apto #',
          // labelEs: 'Numero de Apartamento',
          sm: 3
        },
        {
          prop: 'city',
          label: 'City',
          labelEs: 'Ciudad',
          rules: requiredRules('City'),
          sm: 6
        },
        {
          prop: 'state',
          label: 'State',
          labelEs: 'Estado',
          type: 'autocomplete',
          items: states,
          itemsEs: states,
          rules: requiredRules('State'),
          sm: 3
        },
        {
          prop: 'postalCode',
          label: 'Postal Code',
          labelEs: 'Código Postal',
          rules: requiredRules('Postal Code'),
          sm: 3
        },
        {
          prop: 'phone',
          label: 'Phone',
          labelEs: 'Numero de Teléfono',
          rules: requiredPhoneRules('Phone'),
          md: 6
        },
        {
          prop: 'email',
          label: 'Email',
          labelEs: 'Dirección de correo electrónico',
          inputType: 'email',
          // rules: requiredRules('Email'),
          md: 6
        },
        {
          prop: 'driversLicense',
          label: 'Driver\'s License',
          labelEs: 'Licencia de Conducir',
          // rules: requiredRules('Driver\'s License'),
          md: 6
        },
        {
          prop: 'cdl',
          label: 'CDL',
          labelEs: 'CDL',
          type: 'checkbox',
          // rules: requiredRules('Driver\'s License'),
          md: 6
        },
        {
          prop: 'dob',
          label: 'Date of Birth',
          labelEs: 'Fecha de Nacimiento',
          type: 'dob',
          rules: requiredRules('Date of Birth/Fecha de Nacimiento'),
          sm: 6
        },
        {
          prop: 'desiredSalary',
          label: 'Desired Salary',
          labelEs: 'Salario Deseado',
          inputType: 'number',
          rules: salaryRules('Desired Salary'),
          sm: 6
        },
        // {
        //   prop: 'empty-1',
        //   type: 'spacer',
        //   sm: 6
        // },
        {
          prop: 'citizen',
          label: 'Are you a citizen of the USA?',
          labelEs: '¿Eres ciudadano de los Estados Unidos?',
          type: 'radio-group-yes-no',
          // rules: requiredRules('Are you a ,citizen of the USA'),
          sm: 6
        },
        {
          prop: 'authorizedToWork',
          label: 'Are you authorized to work in the USA?',
          labelEs: '¿Está autorizado para trabajar en los Estados Unidos?',
          type: 'radio-group-yes-no',
          // rules: requiredRules('Are you ,authorized to work in the USA?'),
          sm: 6
        },
        {
          prop: 'positionApplyingFor',
          label: 'Position Applying For',
          labelEs: '¿Posicionando solicitado?',
          rules: requiredRules('Position Applying For'),
          sm: 6
        },
        {
          prop: 'referredBy',
          label: 'Referred By',
          labelEs: '¿Referido por?',
          // rules: requiredRules('Referred By'),
          sm: 6
        },
        {
          prop: 'previouslyWorkedForThisCompany',
          label: 'Have you ever worked for this company?',
          labelEs: '¿Alguna vez has trabajado para esta compañía?',
          type: 'radio-group-yes-no',
          sm: 6
        },
        {
          prop: 'previouslyWorkedNotes',
          label: 'If yes, when?',
          labelEs: '¿Si es asi cuando?',
          // TODO: requiredRulesIf(prop, '**label**')
          // rules: requiredRules('Referred By'),
          sm: 6
        }

      ]
    }
  },
  computed: {
    ea () {
      return this.$store.state.employmentApplication
    },
    lang () {
      return this.$store.state.employmentApplicationLanguage
    }
  },
  methods: {
    updateField,
    continueStep () {
      const valid = this.$refs.form.validate()
      if (valid) {
        this.$emit('continueStep', 'Applicant Information')
      }
    }
  }
}
</script>
