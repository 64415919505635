<template>
  <section class="grey darken-4">
    <BannerHeader
      title="Join Our Team"
      subtitle="Employment Application"
      bgColor="black"
      :height="$vuetify.breakpoint.mdAndDown ? '25vh' : '50vh'"
    />
    <v-row
      align="center"
      class="grey darken-3 grey--text text--lighten-2 my-3 px-4"
    >
      <v-col class="grow pb-0 pb-md-3">
        <div class="d-flex flex-column flex-md-row align-center">
          <h3 class="text-h5 text-md-h4 text-center text-md-left text-uppercase">Online Application</h3>
          <div class="ml-4 my-4 my-sm-0">
            <v-btn-toggle
              active-class="secondary white--text"
              :value="lang"
              >
            <v-btn
              small
              value="English"
              @click="setEmploymentApplicationLanguage('English')"
            >
              English
            </v-btn>
            <v-btn
              small
              value="Español"
              @click="setEmploymentApplicationLanguage('Español')"
            >
              Español
            </v-btn>
          </v-btn-toggle>
          </div>
        </div>
      </v-col>
      <v-col :class="$vuetify.breakpoint.smAndDown ? 'text-center' : 'shrink'">
        <div class="text-caption text-center">{{ lang === 'English'? 'Need a printable copy?' : '¿Necesitas una copia printable?' }}</div>
        <v-btn
          class="white"
          color="secondary"
          outlined
          :href="printableDownloadLink"
          target="_blank"
        >
          <v-icon
            left
            color="primary"
          >fa-file-pdf</v-icon>Download {{ lang === 'English' ? 'Application' : 'Aplicacion' }}
        </v-btn>
      </v-col>
    </v-row>
    <v-container fluid>
      <v-row justify="center" class="my-12">
        <v-col
          md="10"
          lg="7"
          xl="7"
        >
          <template>
            <v-stepper
              v-model="appSteps"
              vertical
              class="elevation-0"
            >
              <v-stepper-step
                :complete="ea.verifiedSections['Applicant Information']"
                step="1"
                class="text-h6 cursor"
                @click="appSteps = 1"
              >
                {{ lang === 'English' ? 'Applicant Information' : 'Informacion de Aplicacion' }}
                <small>{{ lang === 'English' ? 'Enter your information here' : 'Ingrese su información' }}</small>
              </v-stepper-step>

              <v-stepper-content class="pl-4" step="1">
                <AppSectionApplicantInformation
                  @continueStep="continueStep"
                />
              </v-stepper-content>

              <v-stepper-step
                :complete="ea.verifiedSections.Education"
                step="2"
                class="text-h6 cursor"
                @click="appSteps = 2"
              >
              {{ lang === 'English' ? 'Education' : 'Educación ' }}
              </v-stepper-step>

              <v-stepper-content class="pl-4" step="2">
                  <AppSectionEducation
                    @goBack="goBack"
                    @continueStep="continueStep"
                  />
              </v-stepper-content>

              <v-stepper-step
                :complete="ea.verifiedSections['Professional References']"
                step="3"
                class="text-h6 cursor"
                @click="appSteps = 3"
              >
              {{ lang === 'English' ? 'Professional References' : 'Referencias' }}</v-stepper-step>

              <v-stepper-content class="pl-4" step="3">
                <AppSectionReferences
                  @goBack="goBack"
                  @continueStep="continueStep"
                />
              </v-stepper-content>

              <v-stepper-step
                :complete="ea.verifiedSections['Previous Employment']"
                step="4"
                class="text-h6 cursor"
                @click="appSteps = 4"
              >
                {{ lang === 'English' ? 'Previous Employment' : 'Empleo Anterior' }}
              </v-stepper-step>
              <v-stepper-content class="pl-4" step="4">
                <AppSectionPreviousEmployment
                  @goBack="goBack"
                  @continueStep="continueStep"
                />
              </v-stepper-content>

              <v-stepper-step
                step="5"
                class="text-h6 cursor"
                @click="appSteps = 5"
              >{{ lang === 'English' ? 'Submit' : 'Enviar' }}</v-stepper-step>
              <v-stepper-content class="pl-4" step="5">
                <AppSectionAcknowledgement
                  @goBack="goBack"
                />
              </v-stepper-content>
            </v-stepper>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import BannerHeader from '@/core/components/BannerHeader'
import AppSectionApplicantInformation from './components/AppSectionApplicantInformation'
import AppSectionEducation from './components/AppSectionEducation'
import AppSectionReferences from './components/AppSectionReferences'
import AppSectionPreviousEmployment from './components/AppSectionPreviousEmployment'
import AppSectionAcknowledgement from './components/AppSectionAcknowledgement'
import { requiredRules } from '@/core/utils'

export default {
  metaInfo: {
    title: 'Apply',
    description: 'Apply to work at C&C Industrial. Our online and print applications are in Enlish/Español '
  },
  components: {
    BannerHeader,
    AppSectionApplicantInformation,
    AppSectionEducation,
    AppSectionReferences,
    AppSectionPreviousEmployment,
    AppSectionAcknowledgement
  },
  data () {
    return {
      appSteps: 1,
      englishAppLink: 'https://ucarecdn.com/658c72c4-32ee-497d-8d93-eec313e8babf/CCIndustrialEmploymentApplicationen.pdf',
      espanolAppLink: 'https://ucarecdn.com/c305fb95-20bc-4ce5-ae0b-730193b5c40d/CCIndustrialEmploymentApplicationes.pdf'
    }
  },
  computed: {
    ea () {
      return this.$store.state.employmentApplication
    },
    lang () {
      return this.$store.state.employmentApplicationLanguage
    },
    printableDownloadLink () {
      if (this.lang === 'Español') {
        return this.espanolAppLink
      } else return this.englishAppLink
    }
  },
  methods: {
    requiredRules,
    continueStep (payload) {
      this.appSteps = ++this.appSteps
      this.$store.dispatch('employmentApplicationVerifySection',
        { section: payload, verified: true })
    },
    goBack (payload) {
      this.appSteps = --this.appSteps
      this.$store.dispatch('employmentApplicationVerifySection',
        { section: payload, verified: false })
    },
    setEmploymentApplicationLanguage (language) {
      this.$store.commit('setEmploymentApplicationLanguage', language)
    }
  }
}
</script>
