<template>
  <section>
    <div class="d-flex align-center mt-2 py-2 px-4 grey lighten-2 rounded-lg rounded-b-0">
      <h4 class="text-h6 primary--text">
        {{ title }}
      </h4>
      <v-spacer />
      <div v-if="previousCount !== 1 && previousCount === ea.previousEmployerCount">
        <v-btn
          icon
          @click="removeEmployment"
        >
          <v-icon> fa-times </v-icon>
        </v-btn>
      </div>
    </div>
    <section class="py-4">
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            :value="ea[`previousEmployer${previousCount}Company`]"
            color="secondary"
            dense
            :label="lang === 'English' ? 'Company' : 'Empresa'"
            outlined
            :rules="requiredRules('Company/Empresa')"
            @input="
              updateField(`previousEmployer${previousCount}Company`, $event)
            "
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            :value="ea[`previousEmployer${previousCount}Phone`]"
            color="secondary"
            dense
            :label="lang === 'English' ? 'Phone' : 'Número'"
            outlined
            :rules="requiredPhoneRules('Phone/Número')"
            @input="
              updateField(`previousEmployer${previousCount}Phone`, $event)
            "
          />
        </v-col>
      </v-row>
      <v-text-field
        :value="ea[`previousEmployer${previousCount}Address`]"
        color="secondary"
        dense
        :label="lang === 'English' ? 'Address' : 'Dirección'"
        outlined
        :rules="requiredRules('Address/Dirección')"
        @input="updateField(`previousEmployer${previousCount}Address`, $event)"
      />
      <v-text-field
        :value="ea[`previousEmployer${previousCount}Supervisor`]"
        color="secondary"
        dense
        label="Supervisor"
        outlined
        :rules="requiredRules('Supervisor')"
        @input="
          updateField(`previousEmployer${previousCount}Supervisor`, $event)
        "
      />
      <v-row>
        <v-col
          cols="12"
          md="4"
          class="py-0 py-md-3 pb-md-0"
        >
          <v-text-field
            :value="ea[`previousEmployer${previousCount}JobTitle`]"
            color="secondary"
            dense
            :label="lang === 'English' ? 'Job Title' : 'Título Profesional'"
            outlined
            :rules="requiredRules('Job Title/Título Profesional')"
            @input="
              updateField(`previousEmployer${previousCount}JobTitle`, $event)
            "
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="py-0 py-md-3 pb-md-0"
        >
          <v-text-field
            :value="ea[`previousEmployer${previousCount}StartingSalary`]"
            color="secondary"
            dense
            :label="lang === 'English' ? 'Starting Salary' : 'Salario Inicial'"
            outlined
            type="number"
            :rules="salaryRules('Starting Salary/Salario Inicial')"
            @input="
              updateField(
                `previousEmployer${previousCount}StartingSalary`,
                $event
              )
            "
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="py-0 py-md-3 pb-md-0"
        >
          <v-text-field
            :value="ea[`previousEmployer${previousCount}EndingSalary`]"
            color="secondary"
            dense
            :label="lang === 'English' ? 'Ending Salary' : 'Salario Final'"
            outlined
            :rules="salaryRules('Ending Salary/Salario Final')"
            type="number"
            @input="
              updateField(
                `previousEmployer${previousCount}EndingSalary`,
                $event
              )
            "
          />
        </v-col>
        <v-col
          cols="12"
          class="py-0"
        >
          <v-textarea
            :value="ea[`previousEmployer${previousCount}Responsibilities`]"
            auto-grow
            color="secondary"
            dense
            :label="
              lang === 'English' ? 'Responsibilities' : 'Responsabilidades'
            "
            rows="1"
            outlined
            :rules="requiredRules('Responsibilities/Responsabilidades')"
            @input="
              updateField(
                `previousEmployer${previousCount}Responsibilities`,
                $event
              )
            "
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <div class="d-flex">
            <v-text-field
              :value="ea[`previousEmployer${previousCount}From`]"
              class="pr-1"
              color="secondary"
              dense
              :label="lang === 'English' ? 'From' : 'De'"
              outlined
              :rules="dateEnteredRules()"
              @input="
                updateField(`previousEmployer${previousCount}From`, $event)
              "
            />
            <v-text-field
              :value="ea[`previousEmployer${previousCount}To`]"
              class="pl-1"
              color="secondary"
              dense
              :label="lang === 'English' ? 'To' : 'A'"
              outlined
              :rules="ea[`previousEmployer${previousCount}To`] ? dateEnteredRules() : []"
              @input="updateField(`previousEmployer${previousCount}To`, $event)"
            />
          </div>
        </v-col>
        <v-col
          cols="12"
          md="8"
        >
          <v-textarea
            :value="ea[`previousEmployer${previousCount}ReasonForLeaving`]"
            auto-grow
            color="secondary"
            dense
            :label="
              lang === 'English' ? 'Reason for leaving' : 'Motivo de irse'
            "
            outlined
            rows="1"
            :rules="requiredRules('Reason for leaving/Motivo de irse')"
            @input="
              updateField(
                `previousEmployer${previousCount}ReasonForLeaving`,
                $event
              )
            "
          />
        </v-col>
        <v-col>
          <div class="text-body-2">
            {{
              lang === "English"
                ? "May we contact your previous supervisor for a reference?"
                : "¿Podemos contactar a su supervisor anterior para una referencia?"
            }}
          </div>
          <v-radio-group
            :value="ea[`previousEmployer${previousCount}Contact`]"
            class="mt-0"
            dense
            row
            @change="updateField(`previousEmployer${previousCount}Contact`, $event)"
          >
            <v-radio
              :label="lang === 'English' ? 'Yes' : 'Sí'"
              value="Yes"
            />
            <v-radio
              label="No"
              value="No"
            />
          </v-radio-group>
        </v-col>
      </v-row>
    </section>
  </section>
</template>

<script>
import { requiredRules, requiredPhoneRules, phoneRules, requiredDateEnteredRules, dateEnteredRules, salaryRules } from '@/core/utils'
import { updateField } from '../utils'

export default {
  props: {
    title: {
      type: String
    },
    previousCount: {
      type: Number
    },
    noPreviousEmployment: {
      type: Boolean
    }
  },
  computed: {
    ea () {
      return this.$store.state.employmentApplication
    },
    lang () {
      return this.$store.state.employmentApplicationLanguage
    }
  },
  methods: {
    updateField,
    phoneRules,
    requiredRules,
    requiredPhoneRules,
    requiredDateEnteredRules,
    salaryRules,
    dateEnteredRules,
    removeEmployment () {
      this.$emit('removeEmployment', this.previousCount)
    }
  }
}
</script>
