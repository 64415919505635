<template>
  <div>
    <v-card flat>
      <v-card-text class="px-0">
        <v-row align="center">
          <v-col class="">
            <v-checkbox
              v-model="noPreviousEmployment"
              :label="lang === 'English' ? 'No Previous Employment' : 'Sin empleo anterior'"
              hide-details
              class="mt-0"
            />
          </v-col>
          <v-col class="px-0 px-md-3">
            <v-btn
              color="info"
              text
              @click="addEmployment"
              :disabled="ea.previousEmployerCount >= 3"
            >
              <v-icon color="info" left> fa-plus </v-icon>
              {{ lang === 'English' ? 'Add Employment' : 'Empleo Adicional' }}
            </v-btn>
          </v-col>
        </v-row>
        <div v-if="!noPreviousEmployment">
          <!-- This is not the solution but there for placeholding -->
          <v-form ref="form">
            <AppSectionPreviousEmploymentSingle
              v-if="ea.previousEmployerCount >= 1"
              :title="lang === 'English' ? 'Previous Employer 1' : 'Empleador Anterior 1'"
              :previousCount="1"
              :noPreviousEmployment="noPreviousEmployment"
            />
            <AppSectionPreviousEmploymentSingle
              v-if="ea.previousEmployerCount >= 2"
              :title="lang === 'English' ? 'Previous Employer 2' : 'Empleador Anterior 2'"
              :previousCount="2"
              :noPreviousEmployment="noPreviousEmployment"
              @removeEmployment="removeEmployment"
            />
            <AppSectionPreviousEmploymentSingle
              v-if="ea.previousEmployerCount >= 3"
              :title="lang === 'English' ? 'Previous Employer 3' : 'Empleador Anterior 3'"
              :noPreviousEmployment="noPreviousEmployment"
              :previousCount="3"
              @removeEmployment="removeEmployment"
            />
          </v-form>
        </div>
      </v-card-text>
      <AppSectionActions
        @go-back="goBack"
        @continue-step="continueStep"
      />
    </v-card>
  </div>
</template>

<script>
import AppSectionPreviousEmploymentSingle from './AppSectionPreviousEmploymentSingle'
import AppSectionActions from './AppSectionActions'

export default {
  components: {
    AppSectionPreviousEmploymentSingle,
    AppSectionActions
  },
  data () {
    return {
      noPreviousEmployment: false,
      previousEmploymentCount: 1
    }
  },
  computed: {
    ea () {
      return this.$store.state.employmentApplication
    },
    lang () {
      return this.$store.state.employmentApplicationLanguage
    }
  },
  methods: {
    goBack () {
      this.$emit('goBack', 'Previous Employment')
    },
    continueStep () {
      if (this.noPreviousEmployment) {
        this.$emit('continueStep', 'Previous Employment')
      } else {
        const valid = this.$refs.form.validate()
        if (valid) {
          this.$emit('continueStep', 'Previous Employment')
        }
      }
    },
    addEmployment () {
      if (this.previousEmploymentCount >= 3) {
        return
      }
      this.$store.dispatch('addPreviousEmployer')
    },
    removeEmployment (e) {
      this.$store.dispatch('removePreviousEmployer', e)
    }
  }
}
</script>
