<template>
  <div>
    <v-card flat>
      <v-card-text class="px-0">
        <v-form ref="form">
          <div v-if="scriptsLoaded">
            <v-card
              outlined
              class="mb-8"
            >
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <h4 class="text-h6">{{lang === 'English' ? 'Upload Resume' : 'Subir un Currículum' }}</h4>
                  </v-col>
                  <v-col
                    cols="12"
                    md="8"
                    lg="6"
                  >
                    <v-file-input
                      v-model="upload"
                      :accept="allowedFileTypes"
                      :clearable="false"
                      color="secondary"
                      dense
                      hint="Allowed files: pdf, docx, doc. Max file size 7mb"
                      outlined
                      persistent-hint
                      prepend-icon="fa-cloud-upload"
                      :rules="rules"
                      @change="addFileToUploadcareInstance"
                    />
                  </v-col>
                  <v-col>
                    <div class="ml-2 mt-2">
                      <div v-show="uploadStatus.state === 'ready' && uploadStatus.progress === 1">
                        <v-icon color="success">
                          fa-check
                        </v-icon>
                        File Uploaded
                      </div>
                      <div v-show="uploadStatus.state === 'uploading'">
                        <v-progress-circular
                          color="secondary"
                          indeterminate
                        />
                        Uploading
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
          <v-textarea
            :value="ea.notesFromApplicant"
            auto-grow
            :label="
              lang === 'English'
                ? 'Additional Notes (optional)'
                : 'Notas adicionales (es opcional)'
            "
            outlined
            rows="3"
            @input="updateField('notesFromApplicant', $event)"
          />
          <v-row align="start" class="ma-0">
            <v-col class="shrink pr-0">
              <v-checkbox
                :input-value="ea.consentForMvrCheck"
                color="success"
                class="mt-0 pt-0"
                @change="updateField('consentForMvrCheck', $event)"
              />
            </v-col>
            <v-col class="pl-0">
              <div
                v-if="lang === 'English'"
                class="text-body-2"
              >
                <p>
                  I have a valid driver's license and give consent to run an MVR.
                </p>
              </div>
              <div v-else>
                <p>
                  I have a valid driver's license and give consent to run an MVR.
                </p>
              </div>
            </v-col>
          </v-row>
          <v-row align="start" class="ma-0">
            <v-col class="shrink pr-0">
              <v-checkbox
                :input-value="ea.consentForBackgroundCheck"
                color="success"
                class="mt-0 pt-0"
                @change="updateField('consentForBackgroundCheck', $event)"
              />
            </v-col>
            <v-col class="pl-0">
              <div
                v-if="lang === 'English'"
                class="text-body-2"
              >
                <p>
                  I give my consent to run a background check
                </p>
              </div>
              <div v-else>
                <p>
                  I give my consent to run a background check
                </p>
              </div>
            </v-col>
          </v-row>
          <v-row align="start" class="ma-0">
            <v-col class="shrink pr-0">
              <v-checkbox
                :input-value="ea.acknowledgement"
                color="success"
                class="mt-0 pt-0"
                :error="!ea.acknowledgement"
                :rules="requiredRules('Acknowledgement')"
                @change="updateField('acknowledgement', $event)"
              />
            </v-col>
            <v-col class="pl-0">
              <div
                v-if="lang === 'English'"
                class="text-body-2"
              >
                <p>
                  I certify that my answers are true and complete to the best of
                  my knowledge.
                </p>
                <p>
                  If this application leads to employment, I understand that
                  false or misleading information in my application or interview
                  may result in my release.
                </p>
              </div>
              <div v-else>
                <p>
                  Certifico que mis respuestas son verdaderas y completes a lo
                  mejor de mi conocimiento.
                </p>
                <p>
                  Si esta solicitud conduce al empleo, entiendo que la
                  información falsa o engañosa en mi solicitud o entrevista
                  puede resultar en mi divulgación.
                </p>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <AppSectionActions
        submit
        @go-back="goBack"
        @submit="submitApplication"
        @submitting="submitting"
      />
    </v-card>
  </div>
</template>

<script>
import { requiredRules, phoneRules, requiredPhoneRules, delay } from '@/core/utils'
import { updateField } from '../utils'
import ApplicationRepository from '../db/ApplicationRepository'
import AppSectionActions from './AppSectionActions'

export default {
  components: {
    AppSectionActions
  },
  computed: {
    ea () {
      return this.$store.state.employmentApplication
    },
    lang () {
      return this.$store.state.employmentApplicationLanguage
    },
    submitting () {
      return this.$store.state.submittingApplication
    }
  },
  data () {
    return {
      valid: null,
      scriptsLoaded: false,
      upload: null,
      file: null,
      allowedFileTypes: ['.doc', '.docx', '.pdf', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
      rules: [
        value => !value || (value && value.size < 1024 * 1024 * 7) || 'File size should be less than 7 MB!'
      ],
      uploadStatus: {
        state: null,
        progress: 0
      },
      fileInfo: {}
    }
  },
  methods: {
    updateField,
    phoneRules,
    requiredPhoneRules,
    requiredRules,
    goBack () {
      this.$emit('goBack', 'acknowledgement')
    },
    addFileToUploadcareInstance () {
      if (this.upload) {
        const validation = [
          this.allowedFileTypes.includes(this.upload.type),
          this.upload && this.upload.size < 1024 * 1024 * 7 // less than 7MB
        ]
        if (validation.every(i => i === true)) {
          // uploadcare instance loaded in create hook below
          // eslint-disable-next-line no-undef
          this.file = uploadcare.fileFrom('object', this.upload)
          this.file.progress(uploadInfo => {
            this.uploadStatus = uploadInfo
          })
          this.file.done(fileInfo => {
            const obj = {
              cdnUrl: fileInfo.cdnUrl,
              isStored: fileInfo.isStored,
              mimeType: fileInfo.mimeType,
              name: fileInfo.name,
              originalUrl: fileInfo.originalUrl,
              size: fileInfo.size,
              uuid: fileInfo.uuid
            }
            updateField('resume', obj)
            this.fileInfo = { ...obj }
          })
        }
      }
    },
    async submitApplication () {
      try {
        const arr = [
          this.$refs.form.validate(),
          this.ea.acknowledgement,
          ...Object.values(this.ea.verifiedSections)
        ]
        if (arr.every(i => i === true)) {
          this.$store.commit('setSubmittingApplication', true)
          await ApplicationRepository.submitEmploymentApplication(this.ea)
          await delay(500) // Usually happens fast so this helps with screen blink
          this.$store.commit('setSubmittingApplication', false)
          this.$emit('submitApplication', 'acknowledgement')
          this.$store.commit('resetEmploymentApplication')
          this.$refs.form.reset()
          this.$store.commit('setSnackbar', {
            show: true,
            msg: this.lang === 'English' ? 'Thank you for submitting your application.' : 'Gracias por enviar tú aplicación.',
            color: 'success'
          })
          this.$router.push('/')
        } else {
          const unverifiedSections = []
          for (const [key, value] of Object.entries(this.ea.verifiedSections)) {
            if (value === false) {
              unverifiedSections.push(key)
            }
          }
          if (this.ea.acknowledgement === false) {
            unverifiedSections.push('Confirm Answers')
          }
          let formattedUnverifiedSections = unverifiedSections.join(', ')

          if (this.lang !== 'English') {
            formattedUnverifiedSections = formattedUnverifiedSections
              .replace('Applicant Information', 'Informacion de Aplicacion')
              .replace('Education', 'Educación')
              .replace('Professional References', 'Referencias')
              .replace('Previous Employment', 'Empleo Anterior')
          }

          const msg = `The following sections have not been completed: <span class="font-weight-bold">${formattedUnverifiedSections}</span>`
          this.$store.commit('setSnackbar', {
            show: true,
            msg: msg,
            color: 'error',
            timeout: 4000
          })
        }
      } catch (error) {
        this.$rollbar.error('There was an issue submitting an employment application', error)
        this.$store.commit('setSnackbar', {
          show: true,
          msg: 'There was an issue submitting your application. Please try again or download a blank application to fill out.',
          color: 'error',
          timeout: 4000
        })
      }
    }
  },
  created () {
    /**
     * Load Uploadcare file uploader scripts
     */
    this.$loadScript('https://code.jquery.com/jquery-3.4.1.min.js')
      .then(() => {
        this.$loadScript('https://ucarecdn.com/libs/widget/3.x/uploadcare.api.min.js')
          .then(() => {
            this.scriptsLoaded = true
          })
          .catch(error => {
            this.$rollbar.error('Uploadcare uploadcare.api.min.js not loading on Employment application', error)
          })
      })
      .catch(error => {
        this.$rollbar.error('jQuery not loading on Employment application', error)
      })
  }
}
</script>
