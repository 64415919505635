import Vue from 'vue'
import { fs } from '@/firebase'
import { cleanApplication } from '../db/IApplication'

const vm: any = Vue

export default class EmploymentRepository {
  public static async submitEmploymentApplication (form: any): Promise<any> {
    try {
      const cleanForm = cleanApplication(form)
      const doc = {
        to: 'jobs@ccindustrial.com',
        from: 'jobs@ccindustrial.com',
        template: {
          name: 'application_template',
          data: {
            firstName: cleanForm.firstName,
            lastName: cleanForm.lastName
          }
        },
        ...cleanForm
      }
      await fs.collection('employment_applications')
        .doc()
        .set(doc)
      return {
        success: true
      }
    } catch (error) {
      vm.$rollbar.configure({
        payload: {
          file: 'ApplicationRepository.ts',
          function: 'EmploymentRepository.submitEmploymentApplication()'
        }
      })
      vm.$rollbar.error('Error submitting an employment application from EmploymentRepository.submitEmploymentApplication()', error)
      return {
        success: false,
        error
      }
    }
  }
}
