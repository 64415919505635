var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-form',{ref:"form"},[_c('section',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"px-0 px-md-3",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"value":_vm.ea.highSchoolName,"label":_vm.lang === 'English'
                    ? 'High School'
                    : 'Escuela Preparatoria',"dense":"","color":"secondary","outlined":"","rules":_vm.requiredRules('High School/Escuela Preparatoria')},on:{"input":function($event){return _vm.updateField('highSchoolName', $event, 'High School')}}})],1),_c('v-col',{staticClass:"px-0 px-md-3",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"value":_vm.ea.highSchoolAddress,"label":_vm.lang === 'English' ? 'Address' : 'Dirección',"dense":"","color":"secondary","outlined":""},on:{"input":function($event){return _vm.updateField(
                    'highSchoolAddress',
                    $event,
                    'Address/Dirección'
                  )}}})],1),_c('v-col',{staticClass:"px-0 px-md-3",attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"pr-1",attrs:{"value":_vm.ea.highSchoolAttendedFrom,"label":_vm.lang === 'English' ? 'From (MM/YY)' : 'De (MM/YY)',"dense":"","color":"secondary","outlined":"","rules":_vm.requiredDateEnteredRules('Date')},on:{"input":function($event){return _vm.updateField(
                      'highSchoolAttendedFrom',
                      $event,
                      'High School Attended From'
                    )}}}),_c('v-text-field',{staticClass:"pl-1",attrs:{"value":_vm.ea.highSchoolAttendedTo,"label":_vm.lang === 'English' ? 'To (MM/YY)' : 'A (MM/YY)',"dense":"","color":"secondary","outlined":"","rules":_vm.requiredDateEnteredRules('Date')},on:{"input":function($event){return _vm.updateField(
                      'highSchoolAttendedTo',
                      $event,
                      'High School Attended To'
                    )}}})],1)]),_c('v-col',{staticClass:"px-0 px-md-3",attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.lang === "English" ? "Did you graduate?" : "¿Te graduaste?")+" ")]),_c('v-radio-group',{staticClass:"mt-0",attrs:{"value":_vm.ea.highSchoolGraduate,"dense":"","row":"","rules":_vm.requiredRules('Graduate')},on:{"change":function($event){return _vm.updateField('highSchoolGraduate', $event, 'Graduate')}}},[_c('v-radio',{attrs:{"label":_vm.lang === 'English' ? 'Yes' : 'Sí',"value":"Yes"}}),_c('v-radio',{attrs:{"label":"No","value":"No"}})],1)],1),_c('v-col',{staticClass:"px-0 px-md-3",attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"value":_vm.ea.highSchoolDegree,"label":_vm.lang === 'English' ? 'Degree' : 'Grado de',"dense":"","color":"secondary","outlined":""},on:{"input":function($event){return _vm.updateField(
                    'highSchoolDegree',
                    $event,
                    'High School Degree'
                  )}}})],1)],1)],1),_c('v-divider',{staticClass:"mb-8"}),_c('section',[_c('v-row',[_c('v-col',{staticClass:"px-0 px-md-3",attrs:{"cols":"12","md":"4"}},[_c('v-checkbox',{attrs:{"label":_vm.lang === 'English'
                    ? 'Did you attend college or university?'
                    : '¿Asististe a la universidad?'},model:{value:(_vm.attendCollege),callback:function ($$v) {_vm.attendCollege=$$v},expression:"attendCollege"}})],1)],1),(_vm.attendCollege)?[_c('v-row',[_c('v-col',{staticClass:"px-0 px-md-3",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"value":_vm.ea.collegeName,"label":_vm.lang === 'English' ? 'College' : 'Universidad',"dense":"","color":"secondary","outlined":"","rules":_vm.attendCollege
                      ? _vm.requiredRules('College/Universidad')
                      : null},on:{"input":function($event){return _vm.updateField('collegeName', $event, 'College')}}})],1),_c('v-col',{staticClass:"px-0 px-md-3",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"value":_vm.ea.collegeAddress,"label":_vm.lang === 'English' ? 'Address' : 'Dirección',"dense":"","color":"secondary","outlined":""},on:{"input":function($event){return _vm.updateField('collegeAddress', $event, 'College Address')}}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"px-0 px-md-3",attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"pr-1",attrs:{"value":_vm.ea.collegeAttendedFrom,"label":_vm.lang === 'English' ? 'From (MM/YY)' : 'De (MM/YY)',"dense":"","color":"secondary","outlined":"","rules":_vm.dateEnteredRules()},on:{"input":function($event){return _vm.updateField(
                        'collegeAttendedFrom',
                        $event,
                        'College Attended From'
                      )}}}),_c('v-text-field',{staticClass:"pl-1",attrs:{"value":_vm.ea.collegeAttendedTo,"label":_vm.lang === 'English' ? 'To (MM/YY)' : 'A (MM/YY)',"dense":"","color":"secondary","outlined":"","rules":_vm.dateEnteredRules()},on:{"input":function($event){return _vm.updateField(
                        'collegeAttendedTo',
                        $event,
                        'College Attended To'
                      )}}})],1)]),_c('v-col',{staticClass:"px-0 px-md-3",attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.lang === "English" ? "Did you graduate?" : "¿Te graduaste?")+" ")]),_c('v-radio-group',{staticClass:"mt-0",attrs:{"value":_vm.ea.collegeGraduate,"dense":"","row":""},on:{"change":function($event){return _vm.updateField('collegeGraduate', $event, 'College Graduate')}}},[_c('v-radio',{attrs:{"label":_vm.lang === 'English' ? 'Yes' : 'Sí',"value":"Yes"}}),_c('v-radio',{attrs:{"label":"No","value":"No"}})],1)],1),_c('v-col',{staticClass:"px-0 px-md-3",attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"value":_vm.ea.collegeDegree,"label":_vm.lang === 'English' ? 'Degree' : '',"dense":"","color":"secondary","outlined":""},on:{"input":function($event){return _vm.updateField('collegeDegree', $event, 'College Degree')}}})],1)],1)]:_vm._e(),_c('v-divider',{staticClass:"mb-8"}),_c('v-row',[_c('v-col',{staticClass:"px-0 px-md-3",attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"value":_vm.ea.educationNotes,"auto-grow":"","dense":"","label":_vm.lang === 'English'
                    ? 'Additional Notes (optional)'
                    : 'Notas adicionales (es opcional)',"outlined":"","rows":"1"},on:{"input":function($event){return _vm.updateField(
                    'educationNotes',
                    $event,
                    'Education Notes'
                  )}}})],1)],1)],2)],1)],1),_c('AppSectionActions',{on:{"go-back":_vm.goBack,"continue-step":_vm.continueStep}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }