<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-form ref="form">
          <div class="font-italic mt-n4 mb-4 mb-md-0">
            {{ lang === 'English' ? 'Please list three professional references.' : 'Enumere tres referencias profesionales.' }}
          </div>
          <v-row>
            <template v-for="i in 3">
              <v-col
                :key="`referencesFullName${i}`"
                cols="12"
                sm="6"
                class="py-0 py-md-3 px-0 px-md-3"
              >
                <v-text-field
                  :value="ea[`referencesFullName${i}`]"
                  class="mx-1"
                  color="secondary"
                  dense
                  :label="lang === 'English' ? 'Full Name' : 'Nombre Completo'"
                  outlined
                  :rules="requiredRules('Full Name/Nombre Completo')"
                  @input="updateField(`referencesFullName${i}`, $event)"
                />
              </v-col>
              <v-col
                :key="`referencesPhone${i}`"
                cols="12"
                sm="6"
                class="py-0 py-md-3 px-0 px-md-3"
              >
                <v-text-field
                  :value="ea[`referencesPhone${i}`]"
                  class="mx-1"
                  color="secondary"
                  dense
                  :label="lang === 'English' ? 'Phone' : 'Número de teléfono '"
                  outlined
                  :rules="phoneRules('Phone/Número de teléfono ')"
                  @input="updateField(`referencesPhone${i}`, $event)"
                />
              </v-col>
              <v-col
                v-if="$vuetify.breakpoint.xsOnly"
                :key="`divider-${i}`"
                cols="12"
              >
                <v-divider />
              </v-col>
            </template>
          </v-row>
        </v-form>
      </v-card-text>
      <AppSectionActions
        @go-back="goBack"
        @continue-step="continueStep"
      />
    </v-card>
  </div>
</template>

<script>
import { requiredRules, phoneRules, requiredPhoneRules } from '@/core/utils'
import { updateField } from '../utils'
import AppSectionActions from './AppSectionActions'

export default {
  components: {
    AppSectionActions
  },
  computed: {
    ea () {
      return this.$store.state.employmentApplication
    },
    lang () {
      return this.$store.state.employmentApplicationLanguage
    }
  },
  methods: {
    updateField,
    phoneRules,
    requiredPhoneRules,
    requiredRules,
    goBack () {
      this.$emit('goBack', 'Professional References')
    },
    continueStep () {
      const valid = this.$refs.form.validate()
      if (valid) {
        this.$emit('continueStep', 'Professional References')
      }
    }
  }
}
</script>
