<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-form ref="form">
          <section>
            <v-row align="center">
              <v-col cols="12" md="6" class="px-0 px-md-3">
                <v-text-field
                  :value="ea.highSchoolName"
                  :label="
                    lang === 'English'
                      ? 'High School'
                      : 'Escuela Preparatoria'
                  "
                  dense
                  color="secondary"
                  outlined
                  :rules="requiredRules('High School/Escuela Preparatoria')"
                  @input="updateField('highSchoolName', $event, 'High School')"
                />
              </v-col>
              <v-col cols="12" md="6" class="px-0 px-md-3">
                <v-text-field
                  :value="ea.highSchoolAddress"
                  :label="lang === 'English' ? 'Address' : 'Dirección'"
                  dense
                  color="secondary"
                  outlined
                  @input="
                    updateField(
                      'highSchoolAddress',
                      $event,
                      'Address/Dirección'
                    )
                  "
                />
              </v-col>
              <v-col cols="12" md="4" class="px-0 px-md-3">
                <div class="d-flex">
                  <v-text-field
                    :value="ea.highSchoolAttendedFrom"
                    :label="lang === 'English' ? 'From (MM/YY)' : 'De (MM/YY)'"
                    dense
                    color="secondary"
                    class="pr-1"
                    outlined
                    :rules="requiredDateEnteredRules('Date')"
                    @input="
                      updateField(
                        'highSchoolAttendedFrom',
                        $event,
                        'High School Attended From'
                      )
                    "
                  />
                  <v-text-field
                    :value="ea.highSchoolAttendedTo"
                    :label="lang === 'English' ? 'To (MM/YY)' : 'A (MM/YY)'"
                    dense
                    color="secondary"
                    class="pl-1"
                    outlined
                    :rules="requiredDateEnteredRules('Date')"
                    @input="
                      updateField(
                        'highSchoolAttendedTo',
                        $event,
                        'High School Attended To'
                      )
                    "
                  />
                </div>
              </v-col>
              <v-col cols="12" md="4" class="px-0 px-md-3">
                <div class="text-body-2">
                  {{
                    lang === "English"
                      ? "Did you graduate?"
                      : "¿Te graduaste?"
                  }}
                </div>
                <v-radio-group
                  :value="ea.highSchoolGraduate"
                  dense
                  row
                  class="mt-0"
                  :rules="requiredRules('Graduate')"
                  @change="
                    updateField('highSchoolGraduate', $event, 'Graduate')
                  "
                >
                  <v-radio
                    :label="lang === 'English' ? 'Yes' : 'Sí'"
                    value="Yes"
                  />
                  <v-radio label="No" value="No" />
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="4" class="px-0 px-md-3">
                <v-text-field
                  :value="ea.highSchoolDegree"
                  :label="lang === 'English' ? 'Degree' : 'Grado de'"
                  dense
                  color="secondary"
                  outlined
                  @input="
                    updateField(
                      'highSchoolDegree',
                      $event,
                      'High School Degree'
                    )
                  "
                />
              </v-col>
            </v-row>
          </section>
          <v-divider class="mb-8" />
          <section>
            <v-row>
              <v-col cols="12" md="4" class="px-0 px-md-3">
                <v-checkbox
                  v-model="attendCollege"
                  :label="
                    lang === 'English'
                      ? 'Did you attend college or university?'
                      : '¿Asististe a la universidad?'
                  "
                />
              </v-col>
            </v-row>
            <template v-if="attendCollege">
              <v-row>
                <v-col cols="12" md="6" class="px-0 px-md-3">
                  <v-text-field
                    :value="ea.collegeName"
                    :label="lang === 'English' ? 'College' : 'Universidad'"
                    dense
                    color="secondary"
                    outlined
                    :rules="
                      attendCollege
                        ? requiredRules('College/Universidad')
                        : null
                    "
                    @input="updateField('collegeName', $event, 'College')"
                  />
                </v-col>
                <v-col cols="12" md="6" class="px-0 px-md-3">
                  <v-text-field
                    :value="ea.collegeAddress"
                    :label="lang === 'English' ? 'Address' : 'Dirección'"
                    dense
                    color="secondary"
                    outlined
                    @input="
                      updateField('collegeAddress', $event, 'College Address')
                    "
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4" class="px-0 px-md-3">
                  <div class="d-flex">
                    <v-text-field
                      :value="ea.collegeAttendedFrom"
                      :label="lang === 'English' ? 'From (MM/YY)' : 'De (MM/YY)'"
                      dense
                      color="secondary"
                      outlined
                      class="pr-1"
                      :rules="dateEnteredRules()"
                      @input="
                        updateField(
                          'collegeAttendedFrom',
                          $event,
                          'College Attended From'
                        )
                      "
                    />
                    <v-text-field
                      :value="ea.collegeAttendedTo"
                      :label="lang === 'English' ? 'To (MM/YY)' : 'A (MM/YY)'"
                      dense
                      color="secondary"
                      class="pl-1"
                      outlined
                      :rules="dateEnteredRules()"
                      @input="
                        updateField(
                          'collegeAttendedTo',
                          $event,
                          'College Attended To'
                        )
                      "
                    />
                  </div>
                </v-col>
                <v-col cols="12" md="4" class="px-0 px-md-3">
                  <div class="text-body-2">
                    {{
                      lang === "English"
                        ? "Did you graduate?"
                        : "¿Te graduaste?"
                    }}
                  </div>
                  <v-radio-group
                    :value="ea.collegeGraduate"
                    dense
                    row
                    class="mt-0"
                    @change="
                      updateField('collegeGraduate', $event, 'College Graduate')
                    "
                  >
                    <v-radio
                      :label="lang === 'English' ? 'Yes' : 'Sí'"
                      value="Yes"
                    />
                    <v-radio label="No" value="No" />
                  </v-radio-group>
                </v-col>
                <v-col cols="12" md="4" class="px-0 px-md-3">
                  <v-text-field
                    :value="ea.collegeDegree"
                    :label="lang === 'English' ? 'Degree' : ''"
                    dense
                    color="secondary"
                    outlined
                    @input="
                      updateField('collegeDegree', $event, 'College Degree')
                    "
                  />
                </v-col>
              </v-row>
            </template>
            <v-divider class="mb-8" />
            <v-row>
              <v-col cols="12" class="px-0 px-md-3">
                <v-textarea
                  :value="ea.educationNotes"
                  auto-grow
                  dense
                  :label="
                    lang === 'English'
                      ? 'Additional Notes (optional)'
                      : 'Notas adicionales (es opcional)'
                  "
                  outlined
                  rows="1"
                  @input="
                    updateField(
                      'educationNotes',
                      $event,
                      'Education Notes'
                    )
                  "
                />
              </v-col>
            </v-row>
          </section>
        </v-form>
      </v-card-text>
      <AppSectionActions
        @go-back="goBack"
        @continue-step="continueStep"
      />
    </v-card>
  </div>
</template>

<script>
import { requiredRules, dateEnteredRules, requiredDateEnteredRules } from '@/core/utils'
import { updateField } from '../utils'
import AppSectionActions from './AppSectionActions'

export default {
  components: {
    AppSectionActions
  },
  data () {
    return {
      attendCollege: false
    }
  },
  computed: {
    ea () {
      return this.$store.state.employmentApplication
    },
    lang () {
      return this.$store.state.employmentApplicationLanguage
    }
  },
  methods: {
    updateField,
    requiredRules,
    dateEnteredRules,
    requiredDateEnteredRules,
    goBack () {
      this.$emit('goBack', 'Education')
    },
    continueStep () {
      const valid = this.$refs.form.validate()
      if (valid) {
        this.$emit('continueStep', 'Education')
      }
    }
  }
}
</script>
