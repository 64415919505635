<template>
  <v-card-actions>
    <v-btn
      v-if="!hideBack"
      small
      color="secondary"
      text
      @click="$emit('go-back')"
    >
      <v-icon
        small
        class="mr-1"
        color="secondary"
      >fas fa-chevron-left</v-icon>
      {{ lang === "English" ? "Go Back" : "Sección Previa" }}
    </v-btn>
    <v-btn
      v-if="!submit"
      color="secondary"
      @click="$emit('continue-step')"
    >
      {{ lang === "English" ? "Continue" : "Siguiente Sección" }}
    </v-btn>
    <v-btn
      v-if="submit"
      color="secondary"
      :disabled="submitting"
      large
      @click="$emit('submit')"
    >
      {{ lang === "English" ? "Submit" : "Enviar" }}
      <v-progress-circular
        v-show="submitting"
        class="ml-2"
        color="secondary"
        indeterminate
      />
      <v-icon
        v-show="!submitting"
        right
      >
        fa-paper-plane
      </v-icon>
    </v-btn>
  </v-card-actions>
</template>

<script>
export default {
  props: {
    hideBack: {
      type: Boolean,
      default: false
    },
    submit: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    lang () {
      return this.$store.state.employmentApplicationLanguage
    },
    submitting () {
      return this.$store.state.submittingApplication
    }
  }
}
</script>
